<template>
    <div>
        <NavBarComponent />
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <LoginIdeasComponent />
                </div>
                <div class="col-md-6">
                    <LoginFormComponent />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import NavBarComponent from '@/components/NavBarComponent.vue';
import LoginFormComponent from '@/components/LoginFormComponent.vue';
import LoginIdeasComponent from '@/components/LoginIdeasComponent.vue'
export default {
    name: 'LoginView',
    components: {
        NavBarComponent,
        LoginFormComponent,
        LoginIdeasComponent
    }
}
</script>

<style scoped></style>

const buttonSend = document.getElementById("buttonSend");
buttonSend.addEventListener("click", () => {
    const email = document.getElementById("exampleInputEmail1");
    const pass = document.getElementById("exampleInputPassword1");
    const formdata = new FormData();
    formdata.append("user", email.value);
    formdata.append("pass", pass.value);
    const requestOptions = {
        method: 'POST',
        body: formdata,
        credentials: 'include',
        redirect: 'follow',
        mode: 'cors'
    };
    fetch("https://rafalopez.ar/v1/login", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
});