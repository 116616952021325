<!-- <template>
    <div class="container">
        <div class="row">
            <div class="col-12 pt-5">
                <img src="https://picsum.photos/id/433/900/300" alt="Imagen" class="img-fluid">
                <div class="texto-superpuesto">
                    <h1>Adoptar es bueno para todos</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.texto-superpuesto {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
}
@media (min-width: 998px){ 
    .texto-superpuesto{
       top: 30%;
    }
}

@media (max-width: 550px){ 
    .texto-superpuesto{
        position: relative;
    }
}
</style> -->
<template>
    <div class="container pt-5">
                <div class="card">
                    <img src="https://picsum.photos/id/433/900/300" class="img-fluid" alt="Imagen">
                    <div class="card-img-overlay">
                        <h1 class="fst-italic text-body-emphasis card-title shadow p-3 mb-5 rounded">Adoptar es bueno para todos</h1>
                        <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        <p class="card-text"><small>Last updated 3 mins ago</small></p> -->
                    </div>
                </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
/* .texto{
    position: absolute;
    top: 20%;
} */
</style>