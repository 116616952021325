<template>
    <div class="pt-5">
        <h3 class="text-success">Algo que encierre una idea</h3>

        <p class="text-success pb-2">Una explicacion del titulo de arriba un poco mas en detalle</p>
    
        <ul class="pt-5">
            <li class="text-white mb-2">Adoptar implica tener una mascota nueva</li>
            <li class="text-white mb-2">Adoptar es bueno para muchas personas</li>
            <li class="text-white mb-2">Trae felicidad a todos los participantes</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name:'LoginIdeasComponent'
    }
</script>

<style scoped>
li{
    list-style: none;
}
</style>